import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

//
// set custom pdf worker path.
// This custom pdf worker is modified to enable digital signature in pdf.
//      More info: https://stackoverflow.com/questions/57380989/preview-pdf-file-with-e-signature-in-angular
//
// Download PDF worker js file from https://cdn.jsdelivr.net/npm/pdfjs-dist@{PDF_WORKER_VERSION_HERE}/legacy/build/pdf.worker.min.js
(<any>(
  window
)).pdfWorkerSrc = `/assets/scripts/pdf.worker.min.js`;
pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/scripts/pdf.worker.min.js`;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
